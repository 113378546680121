












import { Component, Vue } from "vue-property-decorator";
import { loginModule, PwChangeState } from "../store/modules/LoginModule";
import PwRequestFormView from "./PwRequestFormView.vue";
import PwFormFinishedView from "./PwFormFinishedView.vue";

@Component({
  components: {
    PwRequestFormView,
    PwFormFinishedView,
  },
})
export default class PwRequestForm extends Vue {
  get state(): PwChangeState {
    return loginModule.pwChangeState;
  }

  get loading(): boolean {
    return this.state == "loading";
  }

  get done(): boolean {
    return this.state == "requested";
  }

  mounted() {
    loginModule.setPwChangeState("none");
  }

  request(email: string) {
    loginModule.requestPassword(email);
  }

  cancel() {
    this.$router.back();
  }
}
