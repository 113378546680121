





































import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component
export default class PwRequestFormView extends Vue {
  @Prop() loading!: boolean;

  private email = "";

  get disabled() {
    return this.email == "";
  }

  @Emit()
  request(): string {
    return this.email;
  }

  @Emit()
  cancel() {}
}
