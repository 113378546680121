










import { Component, Vue } from "vue-property-decorator";
import PwRequestForm from "@/components/PwRequestForm.vue";

@Component({
  components: {
    PwRequestForm,
  },
})
export default class PwRequest extends Vue {}
